import * as React from "react";
import { Link, graphql } from "gatsby";
import * as tocbot from "tocbot";
import { RiArrowUpSLine } from "react-icons/ri";
import { MdOutlineClass } from "react-icons/md";
import { Drawer } from "antd";
// import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Comments from "../components/comments";
import { INVALID_DATE } from "../helper/contants";
import "./blog-post.css";
import { formatReadingTime } from "../helper/utils";

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const postInfo = post.frontmatter;
  const type = postInfo.type;
  const tags = postInfo.tags.split(",");
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;

  function handleScrollToTop() {
    // 滚动到顶部
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  React.useEffect(() => {
    let num = 0;
    const headerArr = ["H1", "H2", "H3", "H4"];
    const blogContentNode = document.getElementsByClassName("blog-content")[0];
    if (!blogContentNode?.children?.length) {
      return;
    }
    // @ts-ignore
    [...blogContentNode.children].forEach((child) => {
      if (headerArr.includes(child.nodeName)) {
        // 去除空格以及多余标点
        let headerId = child.innerText.replace(
          // eslint-disable-next-line no-useless-escape
          /[\s|\~|`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\_|\+|\=|\||\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?|\：|\，|\。]/g,
          ""
        );
        headerId = headerId.toLowerCase();
        // NOTE 需要确保name唯一，最好加个自增id
        child.setAttribute("id", headerId + "-" + num);
        num++;
      }
    });

    function handleScroll(e) {
      const rootElement = document.documentElement;
      const scrollToTopBtn = document.querySelector(
        ".back-to-top"
      ) as HTMLElement;
      const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
      if (rootElement.scrollTop / scrollTotal > 0.5) {
        // 显示按钮
        scrollToTopBtn.style.bottom = "36px";
        scrollToTopBtn.style.opacity = "1";
      } else {
        // 隐藏按钮
        scrollToTopBtn.style.bottom = "-36px";
        scrollToTopBtn.style.opacity = "0";
      }
    }

    document.addEventListener("scroll", handleScroll);

    tocbot.init({
      // Where to render the table of contents.
      tocSelector: ".js-toc",
      // Where to grab the headings to build the table of contents.
      contentSelector: ".blog-content",
      // Which headings to grab inside of the contentSelector element.
      headingSelector: "h1, h2, h3, h4",
      // For headings inside relative or absolute positioned containers within content
      hasInnerContainers: false,
      // Main class to add to links.
      linkClass: "toc-link",
      // Extra classes to add to links.
      extraLinkClasses: "",
      // Class to add to active links,
      // the link corresponding to the top most heading on the page.
      activeLinkClass: "is-active-link",
      // Main class to add to lists.
      listClass: "toc-list",
      // Extra classes to add to lists.
      extraListClasses: "",
      // Class that gets added when a list should be collapsed.
      isCollapsedClass: "is-collapsed",
      // Class that gets added when a list should be able
      // to be collapsed but isn't necessarily collapsed.
      collapsibleClass: "is-collapsible",
      // Class to add to list items.
      listItemClass: "toc-list-item",
      // Class to add to active list items.
      // activeListItemClass: "is-active-li",
      // How many heading levels should not be collapsed.
      // For example, number 6 will show everything since
      // there are only 6 heading levels and number 0 will collapse them all.
      // The sections that are hidden will open
      // and close as you scroll to headings within them.
      collapseDepth: 0,
      // Smooth scrolling enabled.
      scrollSmooth: true,
      // Smooth scroll duration.
      scrollSmoothDuration: 420,
      // Smooth scroll offset.
      scrollSmoothOffset: 0,
      // Callback for scroll end.
      scrollEndCallback: function (e) {},
      // Headings offset between the headings and the top of the document (this is meant for minor adjustments).
      headingsOffset: 1,
      // Timeout between events firing to make sure it's
      // not too rapid (for performance reasons).
      throttleTimeout: 50,
      // Element to add the positionFixedClass to.
      positionFixedSelector: null,
      // Fixed position class to add to make sidebar fixed after scrolling
      // down past the fixedSidebarOffset.
      positionFixedClass: "is-position-fixed",
      // fixedSidebarOffset can be any number but by default is set
      // to auto which sets the fixedSidebarOffset to the sidebar
      // element's offsetTop from the top of the document on init.
      fixedSidebarOffset: "auto",
      // includeHtml can be set to true to include the HTML markup from the
      // heading node instead of just including the textContent.
      includeHtml: false,
      // includeTitleTags automatically sets the html title tag of the link
      // to match the title. This can be useful for SEO purposes or
      // when truncating titles.
      includeTitleTags: false,
      // onclick function to apply to all links in toc. will be called with
      // the event as the first parameter, and this can be used to stop,
      // propagation, prevent default or perform action
      onClick: function (e) {},
      // orderedList can be set to false to generate unordered lists (ul)
      // instead of ordered lists (ol)
      orderedList: true,
      // If there is a fixed article scroll container, set to calculate titles' offset
      scrollContainer: null,
      // prevent ToC DOM rendering if it's already rendered by an external system
      skipRendering: false,
      // Optional callback to change heading labels.
      // For example it can be used to cut down and put ellipses on multiline headings you deem too long.
      // Called each time a heading is parsed. Expects a string and returns the modified label to display.
      // Additionally, the attribute `data-heading-label` may be used on a heading to specify
      // a shorter string to be used in the TOC.
      // function (string) => string
      // headingLabelCallback: false,
      // ignore headings that are hidden in DOM
      ignoreHiddenElements: false,
      // Optional callback to modify properties of parsed headings.
      // The heading element is passed in node parameter and information parsed by default parser is provided in obj parameter.
      // Function has to return the same or modified obj.
      // The heading will be excluded from TOC if nothing is returned.
      // function (object, HTMLElement) => object | void
      headingObjectCallback: null,
      // Set the base path, useful if you use a `base` tag in `head`.
      basePath: "",
      // Only takes affect when `tocSelector` is scrolling,
      // keep the toc scroll position in sync with the content.
      disableTocScrollSync: false,
    });

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{postInfo.title}</h1>
          <div className="post-info">
            <span className="post-info-item">发表于 {postInfo.createTime}</span>
            {postInfo.updateTime !== INVALID_DATE && (
              <span className="post-info-item">
                更新于 {postInfo.updateTime}
              </span>
            )}
            <span className="post-info-item">
              <MdOutlineClass
                style={{ marginRight: 4, verticalAlign: "middle" }}
              />
              <a>{postInfo.type}</a>
            </span>
            <span style={{ marginLeft: 8 }}>{`${formatReadingTime(
              post.timeToRead
            )}`}</span>
          </div>
        </header>
        <section
          className="blog-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        {/* <footer>
          <Bio />
        </footer> */}
      </article>
      <div className="blog-post-tags">
        {tags.map((tag) => {
          return (
            <span key={tag} className="blog-post-tag">
              {tag}
            </span>
          );
        })}
      </div>
      <hr />
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <div className="js-toc"></div>
      <span className="back-to-top" onClick={handleScrollToTop}>
        <RiArrowUpSLine />
      </span>
      <Comments />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      frontmatter {
        title
        createTime(formatString: "YYYY/MM/DD")
        updateTime(formatString: "YYYY/MM/DD")
        description
        tags
        type
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
