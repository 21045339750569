/*
 * @Desc:
 * @Author: JacksonZhou
 * @Date: 2021/11/28
 * @LastEditTime: 2021/12/12
 */

import * as React from "react";
import { useEffect, useRef } from "react";

const Comments = (props: { type?: "msgBoard" }) => {
  const commentsRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let title = "title";
    if (props.type === "msgBoard") {
      title = "留言板";
    }
    const script = document.createElement("script");
    script.src = "https://utteranc.es/client.js";
    script.setAttribute("repo", "GitHubJackson/blog-comments");
    script.setAttribute("issue-term", title);
    script.setAttribute("label", "💬");
    script.setAttribute("theme", "github-light");
    script.setAttribute("crossorigin", "anonymous");
    script.async = true;

    if (commentsRef.current) {
      commentsRef.current.appendChild(script);
    }

    return () => {
      if (commentsRef.current) {
        commentsRef.current.innerHTML = "";
      }
    };
  }, []);
  return <div ref={commentsRef} />;
};

export default Comments;
